import React from 'react'
import { Link } from 'gatsby'
import LayoutBs from '../../components/LayoutBs'
import Header from '../../components/Header/header'
import Footer from '../../components/Footer/footer'
import "animate.css/animate.min.css";
import about_image from '../../images/wellovci-na-terasi-well-offica-u-scc-sarajevo.jpg';
import Helmet from 'react-helmet'
import AOS from 'aos';

 class AboutUsPageBs extends React.Component {
  componentDidMount(){
    AOS.init({
      duration : 500
    })
  }
  render() {

    return (
      <LayoutBs>
          <Helmet
          title="WELL – O NAMA | Agencija za digitalni marketing"
          meta={[
            { name: 'description', content: 'WELL-usluge se protežu od najzahtjevnijih web dizajna, preko SEO optimizacije pa sve do modernih metoda oglašavanje za Google i društvene medije.' },
            {name: 'og:image', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg" },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg"},
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
            ]} >
        </Helmet>
          <div id="about_us">
          <Header prefix_string_page="bs"/>
          <div data-aos='fade-zoom-in'>
          
            <section id="p_about_us" className="section pages">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
            <div className="container">        
       
                <h1 className="title">O nama</h1>

                <div className="content">
    <p><strong>
    Svjesni ste važnosti digitalnih medija za budućnost poslovanja Vaše kompanije? 
    Želite poduzeti prvi korak ali ne znate kako? Ne možete procijeniti sa kojim personalnim i finansijskim resursima 
    trebate računati pri promovisanju firme ili prodaji Vaših proizvoda i usluga na internetu?
    </strong></p>
    <p>Ništa novo! Ovo su fundamentalna pitanja sa kojima je suočeno mnoštvo kompanija u današnje vrijeme. 
        Brzina rasta internet tržišta je iznenadila čak i one kompanije koje su se pripremale za internet poslovanje. 
        Obim instrumenata i mogućnosti koje pruža internet poslovanje više nije moguće sagledati bez specijalizovanih 
        marketinških odjela. One firme koje to nemaju, ili si ne mogu priuštiti, nalaze rješenje u kooperaciji sa 
        digitalnim marketinškim agencijama koje im preuzimaju marketinške analize, koncipiranje strategija i 
        sprovođenje marketinško prodajnih aktivnosti.</p>

<h3>Šta Vam “Well” može ponuditi?</h3>
<p>Well spada među agencije koje prate najmodernije tokove i nudi svojim klijentima kompletne marketinške usluge 
    savjetovanja, koncipiranja marketinškog plana i sprovođenja u djelo poslovnih ideja svojih mnogobrojnih klijenata. 
    Od jednostavnih do najzahtjevnijih web stranica, online trgovina i aplikacija preko grafičkog dizajna pa sve do 
    pozicioniranja Vaše web stranice na Google-u (SEO) i najmodernijih metoda oglašavanja preko Google-a i 
    društvenih mreža (Facebook, Instagram, LinkedIn, XING, Twitter i dr.) – sve to spada u spektar profesionalnih 
    usluga Well-a.</p>

<p>Well je izgrađen na 10-godišnjem iskustvu i visokoj stručnoj spremi osoblja. Temeljit rad i konstantna briga o 
    poslovnom uspjehu naših klijenata nas je profilirao u uspješnu digitalno marketinšku agenciju sa renomiranim klijentima. 
    Internacionalno iskustvo i višejezičnost našeg tima – engleski, njemački, turski, španski i slavenski jezici 
    – su nam uveliko pomogli pri pozicioniranju na tržištima Austrije, Engleske, Amerike i Njemačke, a u novije vrijeme 
    i Španije.</p>

<Link to="/bs/portfolio/web-dizajn" target="_blank" className="button gold is-icon-back is-arrow-right">Pogledaj naš portfolio<span class="icon arrow"></span></Link>
<p class="margin-top"><strong>Želite se uklopiti u moderne digitalne tokove a ne znate gdje početi?</strong></p>
<p>Well tim Vam nudi potpuno besplatno savjetovanje (konzalting) pri kojem Vam:</p>
<ul>
    <li>predstavljamo naš tim i način rada,</li>
    <li>analiziramo trenutno stanje Vaše kompanije, </li>
    <li>procijenjujemo mogućnosti realiziranja Vaših ideja </li>
</ul>
<p><strong>Kako stupiti u kontakt?</strong></p>
<p>Jednostavno nas nazovite ili pošaljite upit za termin savjetovanja. Radujemo se Vašem upitu i odgovoramo u roku od 24 sata radnim danima.</p>
<Link to="/bs/kontakt" target="_blank" className="button gold is-icon-back is-arrow-right">Dogovori termin<span class="icon arrow"></span></Link>
<a href="tel:+38761787346" className="line">+387 61 787 346</a>
<a href="mailto:info@wellpromotion.ba" className="line">info@wellpromotion.ba</a>

<p>WELL TIM VAM ŽELI SVAKO DOBRO U POSLOVANJU I RADUJEMO SE SVAKOM NOVOM UPITU I SARADNJI!</p>
<img src={about_image} alt="Well Tim na terasi glavnog offica u Sarajevu,SCC" title="Well Tim na terasi glavnog offica u Sarajevu,SCC" />
<p class="image_alt">Well Tim na terasi glavnog offica u Sarajevu,SCC</p>


                </div>
                </div>
             

                 
</section>
        </div>
        

        </div>

          <Footer/>
      </LayoutBs>
    )
  }
}


export default AboutUsPageBs